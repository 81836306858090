/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Jost:wght@300;400;500;600;700;800&display=swap');


// fonts
$base-font-size: 15;
$base-font: 'Jost', sans-serif;
$heading-font: 'Gilda Display', serif;


// color
$dark-gray: #002642;
$body-color: #848892;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #a3888c;
$theme-primary-color-s2: #c4aaad;
$body-bg-color: #fff;
$section-bg-color: #fbf8f8;
$text-color: #585858;
$text-light-color: #7b7b7b;
$heading-color: $dark-gray;
$border-color: #e7dedf;
$border-color-s2: #e4ebf2;


// change-color

$theme-color-2:#d58675;
$theme-color-3:#1e8267;
$theme-color-4:#de5ca2;
$theme-color-5:#d9ba91;
$theme-color-6:#99337f;
$theme-color-7:#18A7B5;
$theme-color-8:#668c25;
$theme-color-9:#ff8051;
$theme-color-10:#dd6673;
$theme-color-11:#e38138;
$theme-color-12:#b19a56;
