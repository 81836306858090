/*==========================================
7. wpo-about-section
===========================================*/

/* 7.1 wpo-team-section */

.wpo-team-section{
    padding-bottom: 90px;
    padding-top: 0;

    @media(max-width:991px){
      padding-bottom: 70px;
    }
    @media(max-width:575px){
      padding-bottom: 50px;
    }
    .wpo-team-wrap{
        .wpo-team-item{
            text-align: center;
            margin-bottom: 30px;
            padding: 20px;
	        box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.04);

            .wpo-team-img{
                margin: 0 auto;
                position: relative;
                overflow: hidden;

                img{
                    transform: scale(1);
                    transition: all .3s;

                }
            }

            &:hover{
                .wpo-team-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }

            .wpo-team-text{
                padding-top: 20px;
                h3{
                    font-size: 22px;
                    font-weight: 600;

                    a{
                        color: $dark-gray;

                        &:hover{
                            color: $theme-primary-color-s2;
                        }
                    }
                }

                span{
                    font-size: 16px;
                    color: $theme-primary-color;
                }

                ul{
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;

                    li{
                        a{
                            color: #002642;
                            width: 42px;
                            height: 42px;
                            line-height: 42px;
                            background: $section-bg-color;
                            display: block;
                            text-align: center;
                            border-radius: 50%;
                            font-size: 18px;

                            i{
                                font-size: 15px;
                            }

                            &:hover{
                                background: $theme-primary-color-s2;
                                color: $white;
                            }
                        }
                    }

                    li + li {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}