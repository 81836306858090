/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Feelings - Wedding & Planner React Template
Version:        1.0.0
-------------------------------------------------------------------*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

1. General
	1.1 Theme Reset Style
	1.2 Global Elements

2. header
	2.1 topbar
	2.2 navigation

3. content
	3.1 wpo-hero-slider
	3.2 couple-section
	3.3 wpo-story-section
	3.4 wpo-video-section
	3.5 wpo-portfolio-section
	3.6 wpo-contact-section
	3.7 wpo-event-section
	3.8 wpo-partners-section
	3.9 wpo-blog-section

4. wpo-footer

5. Home-style-2
   5.1 wpo-wedding-date
   5.2 couple-section-s2

 6. Home-style-3 
  	6.1 wpo-Service-section
	6.2 wpo-about-section
	6.3 wpo-fun-fact-section
	6.4 wpo-testimonial-area
	6.5 wpo-pricing-section
	
7. wpo-about-section
   7.1 wpo-team-section

8. wpo-service-single

9. coming-soon-section

10. wpo-cart-page-style

11. wpo-checkout-page-style

12. wpo-shop-page

13. wpo-shop-single-page

14. wpo-team-single

15. wpo-portfolio-single-section

16. wpo-blog-pg-section

17. wpo-blog-single-section

18. wpo-contact-pg-section

19. error-404-section

20. color-switcher-section

----------------------------------------------------------------*/

/*------------------------------------------------------------------
1. General
----------------------------------------------------------------*/

// helpers
@import "helpers/variables";
@import "helpers/functions";
@import "helpers/mixins";

// base
@import "base/base";

// components
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/preloader";
@import "components/blog-sidebar";
@import "components/shop-sidebar";
@import "components/pagination";

// layout
@import "layout/header";
@import "layout/hero-slider";
@import "layout/footer";

// components
@import "components/sticky-header";

// page
@import "page/home-default";

@import "page/_home-style2";

@import "page/_home-style3";

@import "page/_about";

@import "page/_service";

@import "page/_coming-soon";

@import "page/_cart";

@import "page/_checkout";

@import "page/_shop";

@import "page/_shop-single";

@import "page/_team-single";

@import "page/_portfolio-single";

@import "page/_blog";

@import "page/_blog-single";

@import "page/_contact";

@import "page/_error-404";

@import "components/custom-components";

.digit {
  color: white !important;
}

.text {
  color: white !important;
}

.deleteButton {
  &:hover {
    cursor: pointer;
    color: rgb(161, 4, 4);
  }
}

.editButton {
  &:hover {
    cursor: pointer;
    color: rgb(204, 174, 52);
  }
}

// .admin-form {
//   margin-bottom: 15rem;
// }

.updateDeleteProduct {
  text-decoration: none;
  li {
    padding: 10px;
  }
}
