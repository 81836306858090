.custom-email-icon{
    &:hover{
        cursor: pointer;
        color: #b9a3a2 !important;
    }
}

.custom-tel-icon{
    &:hover{
        cursor: pointer;
        color: #b9a3a2 !important;
    }
}

.custom-rose{
    color: #e5d5d4;
}